.collapseButton {
  float: right;
  margin: 10px 10px 20px 10px;
}

.logo {
  width: 100%;
  margin: 20px 0;
}

.content {
  padding: 10px;
}

.menu_items {
  :global {
    .ant-menu-item {
      padding: 4px 24px 0
    }
    
  }
}
