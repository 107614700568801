.invoiceModal_content {
    :global {
        .ant-modal-content {
            max-height: 100vh;
            overflow: auto;
        }
    }
}

.modal_footer_hidden { 
    :global {
        .ant-modal-footer { 
            display: none;
        }
    }
}

.formItem_input {
    margin-top: 30px;
    :global {
        .ant-form-item {
            max-width: 60%;
        }
    }
}

.formItem_input_edit {
    margin-top: 30px;
    :global {
        .ant-form-item {
            max-width: 100%;
        }
    }
}

.invoice_paid_status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: '#000000';
}

.alert_message {
    padding: 8px;
    :global {
        .ant-alert-message {
            font-size: 14px;
        }

        .ant-alert-description {
            font-weight: bold;
        }
        
    }
}