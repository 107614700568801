.container {
  display: flex;
  gap: 8px;
}

.widgets {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 400px;
  height: calc(100vh - 20px)
}

.calendarContainer {
  height: 360px;
  position: relative;
  padding: 10px 0;
}

.widgetTabs {
  height: calc(100vh - 360px - 20px - 15px);

  :global {
    .ant-tabs-content, .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.couriers {
  overflow: auto;
  height: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 12px 12px 0 rgb(0 0 0 / 10%);
}

.rangePicker {
  display: flex;
  justify-content: flex-end;
  margin: 0 10px;
}

.calendar {
  box-shadow: none;
}

.calendarDangerCell {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: red;
  z-index: 1;
}

.calendar, .chartContainer {
  width: 400px;
}

.chartContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
}

.collapseButton {
  position: absolute;
  left: 10px;
  top: 10px;
}

.calendarContainer {
  .collapseButton {
    top: 8px;
  }
}

.expandWidgets {
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: self-start;
  cursor: pointer;
}

.contentContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
