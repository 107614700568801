.activeRow {
  background: #E6F4FF;

  :global {
    .ant-table-column-sort {
      background: #E6F4FF !important;
    }
  }

  &:hover {
    :global {
      .ant-table-cell-row-hover {
        background: #E6F4FF !important;
      }
    }
  }
}

.activeRowWithWrongStatus {
  background: #ff4d4f;
  &:hover {
    :global {
      .ant-table-cell-row-hover {
        background: #ff4d4f !important;
      }
    }
  }
}

.wrongStatusRow {
  background: #ffccc7;
  &:hover {
    :global {
      .ant-table-cell-row-hover {
        background: #ffccc7 !important;
      }
    }
  }
}