.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;

  :global {
    .ant-input {
      font-size: 16px !important;
    }
  }
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.collapse {
  border-radius: 0;

  :global {
    .ant-collapse-content-box {
      padding: 0 !important;
      border-radius: 0;
    }

    .ant-descriptions-view {
      border-radius: 0 !important;
    }
  }
}
