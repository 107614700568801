.uploadFiles {
  margin-top: 10px;
  position: relative;

  :global {
   

    .ant-upload-list-item-container {
      width: 88px !important;
      height: 88px !important;
    }

    .ant-upload-select {
      width: 88px !important;
      height: 25px !important;
      margin-top: 22px !important;
    }
    
    .ant-upload-list-item-actions{
      display: flex;
      justify-content: center;
      position: absolute;
      top: 90px;
    }

    svg {
      color: gray;
    }

    a:hover {
      background-color: #f0f0f0;
      border-radius: 4px;
      width: 24px;
      height: 22px
    }
  }
}
