@use "src/styles/bootstrap";
@use "sass:map";

.details {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

@media only screen and (max-width: 1700px) {
  .details {
    grid-template-columns: 1fr;
  }
}
