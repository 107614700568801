@use "src/styles/bootstrap";
@use "sass:map";

.date {
  margin-right: 5px;

  :global {
    .ant-form-item-label {
      width: 60%;
    }

    .ant-picker {
      width: 100%;
    }
  }
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "laptop")) {
  .date {
    width: 100%;
  }
}
