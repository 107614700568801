.status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 3px;
}

.courierInput {
    display: flex;
    align-items: center;
    gap: 5px
}