@use "src/styles/bootstrap";
@use "sass:map";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}


.productDropdownContent {
  position: absolute;
  max-height: 75vh;
  overflow: auto;
  box-shadow: 4px 10px 64px -7px rgba(0,0,0,0.75);
}

.close_btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
}

.jobInputContainer {
  width: 75%;

  & > div:first-child {
    width: 100%;
  }
}

.productName {
  height: auto;
  white-space: normal;
}


@media only screen and (max-width: map.get(bootstrap.$breakpoint, "laptop")) {
  .jobInputContainer {
    width: 65%;
  }
}
