.details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.block {
  height: calc(100vh - 100px);
  overflow: auto;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 12px 12px 0 rgb(0 0 0 / 10%);

  &::-webkit-scrollbar {
    display: none;
  }
}

.job {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.row {
  margin-top: 20px;
  border-top: 1px solid #d9d9d9;
}
