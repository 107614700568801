
.fullscreen_container {
  :global {
    .ant-modal-content {
      padding: 0;
      top: 0;
      padding-bottom: 0;
      @media (max-width: 500px){
        width: 100vw;
        margin-left: -16px;
      }
  }

    .ant-modal-centered, .ant-modal-root {
      @media (max-width: 500px){

      }
    }

    .ant-modal-close-x {
      background-color: #1677ff;
      color: #fff;
      box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
      border-radius: 6px;
      padding: 4px 15px;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      opacity: 0.6;
      &:hover {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
      &:active {
        opacity: 1;
      }
    }
  }
}

.image {
  cursor: pointer;
  max-height: 100px;
  width: 100%;
  object-fit: cover;
}


.fullscreen_image {
  max-height: 100%;
  max-width: 100%; 
  object-fit: contain;

}

.download_btn { 
  position: absolute;
  background-color: #1677ff;
  color: #fff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  border-radius: 6px;
  height: 32px;  
  right: 60px;
  top: 17px;
  padding: 0;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  };
  &:active {
    opacity: 1;
  }
}

