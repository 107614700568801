
.logo_style {
    width: 70px;
    height: 80px;
    margin-bottom: 2px;
    }

.main_section {
    display: flex;
    gap: 15px;
    margin-bottom: 250px;
    }

.additional_section {
    display: flex;
    gap: 50px;
    }

.border_content {
    border: 2px solid black;
    padding: 4px 6px;
    margin-bottom: 8px;
    }

.price_column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    }

.form_border {
    border: 2px solid black;
    }

.form_header {
    text-align: center;
    padding: 2px;
    border-bottom: 2px solid black;
    opacity: 0.7;
    }

.form_body {
    display: flex;
    padding: 1px;
    }

.invis_elem {
    visibility: hidden;
    }

.checkbox {
    width: 14px;
    height: 14px;
    border: 1.5px solid gray;
    border-radius: 2px;
    margin-right: 8px;
    }

.barcode_image {
    width: 120px;
    height: 50px
    }

.table_col1 {
    padding-left: 30px;
}

.table_col2 {
    padding-left: 15px;
}

.table_col {
    padding-left: 10px;
}