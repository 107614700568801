.table_container{
    :global {
        .ant-table {
            margin-inline: -17px -16px !important;
        }

        .ant-table-container .ant-table-content table .ant-table-thead tr th:first-child{
            border-top-left-radius: 0 !important;
        }

        .ant-table-container .ant-table-content table .ant-table-thead tr th:last-child{
            border-top-right-radius: 0 !important;

        }

       
        
    }
}