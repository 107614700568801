@use "src/styles/bootstrap";
@use "sass:map";


.container {
  display: flex;
  gap: 5px;
  width: 100%;
  .formItem {
    width: calc(100% - 35px);


    :global {
      .ant-form-item-label {
        text-align: left !important;
      }
    }
  }
}


