.font_bold {
    font-weight: bold;
    }


.page {
    padding: 15px 10px;
    font-size: 22px;
    }   


.evident_logo { 
    width: 60px;
    height: 25px;
    }


