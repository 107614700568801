.search {
  :global {
    .ant-input-wrapper, .ant-input-affix-wrapper {
      font-size: 16px !important;
    }

    .ant-btn {
      height: 42px;
    }
  }
}
