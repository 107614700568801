.container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  button {
    flex-grow: 1;
  }
}
