.bordered_content { 
    border: 2px solid black;
    width: 400px;
    height: 45px;
}

.field_label {
    font-size: 14px;
}

.footer_border_content { 
    border: 2px solid black;
    padding: 10px; 
    margin: 0 10px 20px
}

.table_border { 
    td, tbody{
        border: 2px solid black;
        border-collapse: collapse;
        }
}
