@use "src/styles/bootstrap";
@use "sass:map";

.container {
  display: flex;
  gap: 5px;

  .formItem {
    width: calc(100% - 31px);


    :global {
      .ant-form-item-label {
        text-align: left !important;
      }
    }
  }
}

@media only screen and (max-width: map.get(bootstrap.$breakpoint, "laptop")) {
  .container .formItem {
    width: calc(100% - 35px);
  }
}
