.content {
  display: flex;
  gap: 8px;
  position: relative;

  & > div {
    height: calc(100vh - 20px);
    overflow: auto;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.fullCase {
  position: absolute;
  z-index: 1;
  left: 70%;
  top: 10px;
  width: 30%;
  backdrop-filter: blur(10px);
}

.fullTask {
  position: absolute;
  z-index: 1;
  right: 40%;
  top: 0;
  width: 60%;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activeRow {
  background: #E6F4FF;

  :global {
    .ant-table-column-sort {
      background: #E6F4FF !important;
    }
  }

  &:hover {
    :global {
      .ant-table-cell-row-hover {
        background: #E6F4FF !important;
      }
    }
  }
}

.actionRow {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

@media print {
  .block {
    display: none;
  }

  .content {
    position: static;
  }
}


